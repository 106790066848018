import type { IconProps } from '../../../../src/features/common/types/common';

import React from 'react';

const FirePopupIcon = ({ width }: IconProps) => {
  return (
    <svg
      width={width}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.5"
        width="18"
        height="18"
        rx="3.33333"
        fill="url(#paint0_linear_1179_76)"
      />
      <path
        d="M8.48697 4.34341C8.72069 3.9591 9.27852 3.9591 9.51225 4.34341L14.8537 13.1262C15.0969 13.5261 14.809 14.038 14.341 14.038H3.65817C3.19019 14.038 2.90236 13.5261 3.14553 13.1262L8.48697 4.34341Z"
        fill="white"
      />
      <path
        d="M8.47842 11.8636C8.53648 11.8889 8.59773 11.8341 8.56218 11.7888C8.36923 11.5427 8.18651 11.1286 8.47762 10.5954C8.96307 9.70617 9.25847 9.24561 9.25847 9.24561C9.25847 9.24561 9.41559 9.78308 9.84151 10.261C10.2514 10.7209 10.4758 11.2993 10.1141 11.7812C10.0801 11.8265 10.14 11.8798 10.1977 11.8556C10.6454 11.668 11.1476 11.2915 11.2043 10.5424C11.2252 10.3135 11.1938 9.99299 11.0372 9.58857C10.8357 9.07601 10.5881 8.83684 10.4448 8.73413C10.4019 8.70341 10.3353 8.73064 10.3388 8.77721C10.3805 9.33026 10.1266 9.47061 9.98208 9.15432C9.92436 9.02799 9.89069 8.80949 9.89069 8.5434C9.89069 8.1004 9.73383 7.64438 9.38802 7.27381C9.29809 7.17744 9.19286 7.08739 9.07214 7.0106C9.02843 6.9828 8.96507 7.01075 8.96908 7.05596C8.99563 7.35628 8.97157 8.21696 8.05003 9.2452C7.21447 10.199 7.53825 10.9315 7.65314 11.1299C7.8728 11.51 8.17919 11.7332 8.47842 11.8636Z"
        fill="#E86F56"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.05682 12.2925C9.24215 12.3986 9.4001 12.4854 9.51624 12.6074C9.62945 12.7264 9.6782 12.7815 9.4908 12.6821C9.31631 12.5893 9.15128 12.4977 8.93686 12.4303C8.92619 12.4367 8.91493 12.443 8.90277 12.4499C7.52512 13.2146 6.20111 12.9863 5.60708 11.001C6.89463 10.9663 8.4559 10.2751 9.02481 12.1372C9.04624 12.2063 9.05927 12.2542 9.05682 12.2925ZM8.54052 12.1385C7.87549 11.5495 6.81672 11.6286 6.14147 11.3003C7.02344 12.0218 7.28891 11.8869 8.54052 12.1385Z"
        fill="#E86F56"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1179_76"
          x1="9"
          y1="9.5"
          x2="21.475"
          y2="25.05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E86F56" />
          <stop offset="1" stopColor="#FA7902" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FirePopupIcon;
